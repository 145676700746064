@font-face {
  font-family: 'Roboto';
  src: url(./Assets/fonts/Roboto/Roboto-Regular.ttf);
}

* {
  font-family: 'Roboto';
  margin: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}
