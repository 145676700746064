body {
  font-size: 16px;
}

.Thanks-background {
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 100vh;
}

.Thanks-header {
  padding-top: 0.5em;
  font-size: 2.5em;
}

.Thanks-content {
  position: absolute;
  top: 40%;
  margin: 0 0 0 5%;
  color: #17214d;
}

.Thanks-paragraph {
  padding-top: 2em;
  color: #17214d;
  font-size: 1.5em;
}

.Thanks-result {
  color: #17214d;
  font-weight: bold;
}

.Thanks-footer {
  display: flex;
  padding: 5%;
  flex-direction: row;
  align-items: flex-end;
  align-content: center;
  justify-content: space-between;
  height: 10em;
  padding-bottom: 2em;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.Thanks-home {
  color: #17214d;
  font-weight: 500;
}
