.Intro {
  height: 100vh;
  background-color: #2291f3;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.Intro-hidden {
  display: none;
}

.Intro-logo {
  width: 65%;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.fade-In {
  opacity: 0;
  animation: fadeIn 2s ease-in forwards;
}
