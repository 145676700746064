.Comment-container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr 10fr;
  padding: 5%;
}

input:focus,
textarea {
  outline: none !important;
}

.Comment-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: rgba(23, 33, 77, 0.38);
  font-size: 0.75em;
  font-weight: 900;
}

.Comment-textarea {
  width: 100%;
  height: 100%;
  resize: none;
  border: 0;
  background-color: #e9ebf2;
  padding-top: 1em;
  font-size: 1.5em;
  color: #17214d;
}

.Comment-footer {
  display: flex;
  padding: 5%;
  flex-direction: row;
  align-items: flex-end;
  align-content: center;
  justify-content: space-between;
  height: 10em;
  padding-bottom: 2em;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.Comment-button {
  display: flex;
  margin: 5% 5% 0 5%;
  padding: 1em;
  border-radius: 0.5em;
  border: none;
  color: #6c728c;
  background-color: #999eb3;
}

.Comment-button-selected {
  display: flex;
  margin: 5% 5% 0 5%;
  padding: 1em;
  border-radius: 0.5em;
  border: none;
  color: white;
  background-color: #13873b;
  box-shadow: 0 8px 16px 0 rgba(10, 111, 45, 0.38);
}

.Comment-skip {
  justify-self: flex-start;
  color: #17214d;
  margin-left: 5%;
  padding-bottom: 2%;
  text-decoration: underline;
}

.Comment-go-to-skip {
  padding-bottom: 1em;
}

.Comment-link {
  text-decoration: none !important;
}
