.InterviewersListItem-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.InterviewersListItem-list {
	display: flex;
	flex-direction: column;
	padding: 1em 0 1em 0;
}

.InterviewersListItem-add {
	display: flex;
	flex-direction: column;
	color: #17214d;
	font-weight: 500;
	align-self: center;
	text-decoration: underline;
	cursor: pointer;
}

.InterviewersListItem-name {
	color: #17214d;
}

.InterviewersListItem-role {
	font-size: 0.8em;
	opacity: 0.87;
	color: #17214d;
}
