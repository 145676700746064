.Rating-container {
  padding: 0 1.5rem;
}

.Rating-dialogue {
  padding: 1.5rem 0;
  color: rgba(23, 33, 77, 0.38);
  font-size: 12px;
  font-weight: 900;
  line-height: 0.875rem;
}

.Rating-logo {
  position: absolute;
  margin-top: 3em;
  z-index: -1;
  right: 0;
}

.Rating-header {
  color: #17214d;
  font-size: 2.5em;
  padding-top: 2.5625rem;
  font-weight: bold;
  line-height: 3.75rem;
}

.Rating-selectors {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.0625rem;
  grid-template-rows: 1fr 1fr;
}

.Rating-selector-one {
  justify-self: center;
  border-radius: 8px;
  height: 11.5rem;
  width: 100%;
  border: solid rgba(0, 54, 204, 0.38) 2px;
  padding: 1em;
  color: #17214d;
}

.Rating-selector-one-selected {
  color: rgba(255, 255, 255, 0.87);
  background-color: #0036cc;
  box-shadow: 0 20px 40px 0 rgba(0, 38, 144, 0.38);
}

.Rating-selector-two {
  justify-self: center;
  height: 11.5rem;
  width: 100%;
  border: solid rgba(32, 131, 66, 0.38) 2px;
  border-radius: 8px;
  padding: 1em;
  color: #17214d;
}

.Rating-selector-two-selected {
  color: rgba(255, 255, 255, 0.87);
  background-color: #208342;
  box-shadow: 0 20px 40px 0 rgba(0, 38, 144, 0.38);
}

.Rating-selector-three {
  justify-self: center;
  height: 11.5rem;
  width: 100%;
  border: solid rgba(23, 33, 77, 0.38) 2px;
  border-radius: 8px;
  padding: 1em;
  color: #17214d;
}

.Rating-selector-three-selected {
  color: rgba(255, 255, 255, 0.87);
  background-color: #17214d;
  box-shadow: 0 20px 40px 0 rgba(0, 38, 144, 0.38);
}

.Rating-selector-four {
  justify-self: center;
  height: 11.5rem;
  width: 100%;
  border-radius: 8px;
  border: solid rgba(216, 60, 22, 0.38) 2px;
  padding: 1em;
  color: #17214d;
}

.Rating-selector-four-selected {
  color: rgba(255, 255, 255, 0.87);
  background-color: #d83c16;
  box-shadow: 0 20px 40px 0 rgba(0, 38, 144, 0.38);
}

.Rating-span {
  text-decoration: underline;
}

.Rating-good {
  width: 3.5rem;
  height: 3.5rem;
}

.Rating-good-tag {
  font-size: 1.125rem;
  padding: 1.5rem 0 0.5625rem 0;
  line-height: 1.3125rem;
}

.Rating-awesome {
  width: 3.5rem;
  height: 3.5rem;
}

.Rating-awesome-tag {
  font-size: 1.125rem;
  padding: 1.5rem 0 0.5625rem 0;
  line-height: 1.3125rem;
}

.Rating-bad {
  width: 3.5rem;
  height: 3.5rem;
}

.Rating-bad-tag {
  font-size: 1.125rem;
  padding: 1.5rem 0 0.5625rem 0;
  line-height: 1.3125rem;
}

.Rating-neutral {
  width: 3.5rem;
  height: 3.5rem;
}

.Rating-neutral-tag {
  font-size: 1.125rem;
  padding: 1.5rem 0 0.5625rem 0;
  line-height: 1.3125rem;
}

.Rating-good-line {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: rgba(23, 33, 77, 0.87);
}

.Rating-good-line-selected {
  color: rgba(255, 255, 255, 0.87);
}

.Rating-awesome-line {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: rgba(23, 33, 77, 0.87);
}

.Rating-awesome-line-selected {
  color: rgba(255, 255, 255, 0.87);
}

.Rating-neutral-line {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: rgba(23, 33, 77, 0.87);
}

.Rating-neutral-line-selected {
  color: rgba(255, 255, 255, 0.87);
}

.Rating-bad-line {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: rgba(23, 33, 77, 0.87);
}

.Rating-bad-line-selected {
  color: rgba(255, 255, 255, 0.87);
}

.Rating-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-content: center;
  justify-content: space-between;
  height: 8em;
  padding-bottom: 2em;
  width: 100%;
  bottom: 0;
  left: 0;
}

.Rating-button {
  display: flex;
  font-size: 1rem;
  margin: 5% 5% 0 5%;
  padding: 1em;
  border-radius: 0.5em;
  border: none;
  color: #6c728c;
  background-color: #999eb3;
}

.Rating-button-enabled {
  display: flex;
  font-size: 1rem;
  margin: 5% 5% 0 5%;
  padding: 1em;
  border-radius: 0.5em;
  border: none;
  color: white;
  background-color: #13873b;
  box-shadow: 0 8px 16px 0 rgba(10, 111, 45, 0.38);
}

.Rating-back {
  justify-self: flex-start;
  color: #17214d;
  padding-bottom: 2%;
  text-decoration: underline;
}

.Rating-go-to-back {
  padding-bottom: 1em;
}

.Rating-link {
  text-decoration: none !important;
}

@media screen and (min-width: 768px) {
  .Rating-container {
    margin: 1.5rem 15%;
  }
  .Rating-footer {
    margin-top: 16.5rem;
  }
}
