.ExtraInfo-container {
  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-rows: 1fr 3fr;
}

.ExtraInfo-header {
  display: flex;
  padding: 4.4375rem 1.5rem 0 1.5rem;
  background-color: #dcdfeb;
  flex-direction: column;
  justify-content: end;
  border-radius: 0 0 1.125em 1.125em;
}

.ExtraInfo-card-awesome {
  display: flex;
  background-color: #0036cc;
  height: 5.625em;
  border-radius: 0.5em;
  box-shadow: 0 20px 40px 0 rgba(0, 38, 144, 0.38);
  margin-top: 1rem;
}

.ExtraInfo-card-good {
  display: flex;
  background-color: #208342;
  height: 5.625em;
  border-radius: 0.5em;
  box-shadow: 0 20px 40px 0 rgba(0, 38, 144, 0.38);
  margin-top: 1rem;
}

.ExtraInfo-card-neutral {
  display: flex;
  background-color: #17214d;
  height: 5.625em;
  border-radius: 0.5em;
  box-shadow: 0 20px 40px 0 rgba(0, 38, 144, 0.38);
  margin-top: 1rem;
}

.ExtraInfo-card-bad {
  display: flex;
  background-color: #d83c16;
  height: 5.625em;
  border-radius: 0.5em;
  box-shadow: 0 20px 40px 0 rgba(0, 38, 144, 0.38);
  margin-top: 1rem;
}

.ExtraInfo-icon-awesome {
  margin: 1rem 0 1rem 1rem;
  width: 3.5rem;
  height: 3.5rem;
}

.ExtraInfo-icon-good {
  margin: 1rem 0 1rem 1rem;
  width: 3.5rem;
  height: 3.5rem;
}

.ExtraInfo-icon-neutral {
  margin: 1rem 0 1rem 1rem;
  width: 3.5rem;
  height: 3.5rem;
}

.ExtraInfo-icon-bad {
  margin: 1rem 0 1rem 1rem;
  width: 3.5rem;
  height: 3.5rem;
}

.ExtraInfo-content-awesome {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ExtraInfo-content-good {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ExtraInfo-content-neutral {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ExtraInfo-content-bad {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ExtraInfo-awesome-tag {
  padding-bottom: 0.5em;
  font-weight: 900;
  font-size: 1.125em;
  color: rgba(255, 255, 255, 0.87);
}

.ExtraInfo-good-tag {
  padding-bottom: 0.5em;
  font-weight: 900;
  font-size: 1.125em;
  color: rgba(255, 255, 255, 0.87);
}

.ExtraInfo-neutral-tag {
  padding-bottom: 0.5em;
  font-weight: 900;
  font-size: 1.125em;
  color: rgba(255, 255, 255, 0.87);
}

.ExtraInfo-bad-tag {
  padding-bottom: 0.5em;
  font-weight: 900;
  font-size: 1.125em;
  color: rgba(255, 255, 255, 0.87);
}

.ExtraInfo-awesome-line {
  color: rgba(255, 255, 255, 0.87);
  font-size: 0.875em;
}

.ExtraInfo-good-line {
  color: rgba(255, 255, 255, 0.87);
  font-size: 0.875em;
}

.ExtraInfo-neutral-line {
  color: rgba(255, 255, 255, 0.87);
  font-size: 0.875em;
}

.ExtraInfo-bad-line {
  color: rgba(255, 255, 255, 0.87);
  font-size: 0.875em;
}

.ExtraInfo-awesome-change {
  text-decoration: underline;
  padding: 2em 0.5rem 0 0;
  font-size: 0.7em;
  color: rgba(255, 255, 255, 0.87);
  font-size: 0.8125em;
  font-weight: 500;
}

.ExtraInfo-good-change {
  text-decoration: underline;
  padding: 2em 0.5rem 0 0;
  font-size: 0.7em;
  color: rgba(255, 255, 255, 0.87);
  font-size: 0.8125em;
  font-weight: 500;
}

.ExtraInfo-neutral-change {
  text-decoration: underline;
  padding: 2em 0.5rem 0 0;
  font-size: 0.7em;
  color: rgba(255, 255, 255, 0.87);
  font-size: 0.8125em;
  font-weight: 500;
}

.ExtraInfo-bad-change {
  text-decoration: underline;
  padding: 2em 0.5rem 0 0;
  font-size: 0.7em;
  color: rgba(255, 255, 255, 0.87);
  font-size: 0.8125em;
  font-weight: 500;
}

.ExtraInfo-change-container {
  margin-left: auto;
}

.ExtraInfo-span {
  font-size: 0.75em;
  color: rgba(22, 32, 78, 0.38);
  font-weight: 900;
}

.ExtraInfo-feedback {
  padding: 3.7725rem 5% 5% 5%;
  position: relative;
}

.ExtraInfo-question {
  color: #17214d;
  font-weight: 500;
  font-size: 1.375em;
}

.ExtraInfo-choices {
  display: flex;
  flex-wrap: wrap;
}

.ExtraInfo-style {
  border: 1px solid rgba(23, 33, 77, 0.38);
  border-radius: 8px;
  font-size: 0.95em;
  background-color: #e9ebf2;
  color: #16204e;
  padding: 0.5em;
  margin: 0.5em;
}

.ExtraInfo-style-selected {
  border: 2px solid #0c6f2e;
  background-color: rgba(19, 135, 59, 0.1);
  border-radius: 8px;
  font-size: 0.95em;
  color: #16204e;
  padding: 0.5em;
  margin: 0.5em;
}

.ExtraInfo-footer {
  position: fixed;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-content: center;
  justify-content: space-between;
  height: 10em;
  padding-bottom: 2em;
  bottom: 0;
}

.ExtraInfo-button-enabled {
  margin-left: 3rem;
  font-size: 1rem;
  display: flex;
  padding: 1em;
  border-radius: 0.5em;
  border: none;
  color: white;
  background-color: #13873b;
  box-shadow: 0 8px 16px 0 rgba(10, 111, 45, 0.38);
}

.ExtraInfo-button {
  margin-left: 3rem;
  font-size: 1rem;
  display: flex;
  padding: 1rem;
  border-radius: 0.5em;
  border: none;
  color: #6c728c;
  background-color: #999eb3;
}

.ExtraInfo-comment {
  padding: 0;
  font-size: 1rem;
  background: none !important;
  border: none;
  color: #17214d;
  padding-bottom: 1rem;
  text-decoration: underline;
}

.ExtraInfo-link {
  text-decoration: none !important;
}

@media screen and (min-width: 768px) {
  .ExtraInfo-header-content {
    margin: 1.5rem 15%;
  }
  .ExtraInfo-feedback {
    margin: 1.5rem 15%;
  }
  .ExtraInfo-good-change {
    padding-left: 40%;
  }

  .ExtraInfo-footer {
    width: 60vw;
  }

  .ExtraInfo-button {
    margin-left: 14rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 568px) {
  .ExtraInfo-header {
    padding-top: 2rem;
  }
}
