body {
	background-color: #e9ebf2;
	height: 100%;
	overflow-x: hidden;
}

.Interviewers-main-hidden {
	z-index: -1;
	display: none;
}

.Interviewers-container {
	margin: 1.5rem;
	align-self: center;
}

.Interviewers-header {
	color: #17214d;
	font-size: 2.5em;
	padding-top: 2.5625rem;
	font-weight: bold;
	padding-bottom: 1rem;
}

.Interviewers-logo {
	position: absolute;
	margin-top: 3em;
	z-index: -1;
	right: 0;
}

.Interviewers-image {
	display: flex;
	float: right;
	margin-right: 50px;
}

.Interviewers-main {
	width: 100vw;
	height: 100vh;
}

.Interviewers-searchbar-container {
	display: flex;
	position: relative;
}

.Interviewers-searchbar {
	padding: 1em;
	font-size: 1em;
	opacity: 0.8;
	width: 100%;
	border-radius: 8px;
	border: 1px solid rgba(23, 33, 77, 0);
	background-color: #eeeff5;
	box-shadow: 0 8px 16px 0 rgba(22, 32, 78, 0.08);
}

.Interviewers-searchbar-icon {
	position: absolute;
	right: 1rem;
	top: 1rem;
	width: 1rem;
}

.Interviewers-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	flex-direction: column;
	width: 100%;
	padding-bottom: 6em;
}

.Interviewers-added {
	padding: 2rem 1em 1rem 0;
	color: rgba(23, 33, 77, 0.38);
	font-size: 12px;
	font-weight: 900;
}

.Interviewers-button {
	display: flex;
	font-size: 1rem;
	margin: 5% 5% 0 5%;
	padding: 1em;
	border-radius: 0.5em;
	border: none;
	color: #6c728c;
	background-color: #999eb3;
}

.Interviewers-button-enabled {
	font-size: 1rem;
	display: flex;
	margin: 5% 5% 0 5%;
	padding: 1rem 0.9375rem 1rem 0.9375rem;
	border-radius: 0.5em;
	border: none;
	color: white;
	background-color: #13873b;
	box-shadow: 0 8px 16px 0 rgba(10, 111, 45, 0.38);
}

.Interviewers-footer {
	padding: 1.5rem;
	display: flex;
	flex-direction: row-reverse;
	justify-content: end;
	align-items: flex-end;
	height: 6em;
	padding-bottom: 2em;
	width: 100%;
	float: right;
	position: fixed;
	bottom: 0;
	left: 0;
	background-image: linear-gradient(to bottom, transparent, rgba(233, 235, 242, 100));
}

.Interviewers-footer-hidden {
	z-index: -1;
}

.Interviewers-link {
	text-decoration: none !important;
}

@media screen and (min-width: 768px) {
	.Interviewers-container {
		margin: 1.5rem 15%;
	}
	.Interviewers-footer {
		padding-right: 8.5rem;
	}
}
